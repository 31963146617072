import React from "react";

const MessageParser = ({ children, actions }) => {
  const parse = (message) => {
    // if(message.includes('hello')){
    //     console.log("hi");
    //     actions.handleHello();
    // } else if(message.includes('javascript')) {
    //     actions.handleJavascriptList();
    // } else {
    //     actions.handleDefault();
    // }

    actions.handleMessage(message);
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions,
        });
      })}
    </div>
  );
};

export default MessageParser;
