import React from "react";
import avatarImage from './chatbot_avatar.png'
import userIcon from './Sample_User_Icon.png'
// const UserIcon = () => {
//   return (
//     <div
//       style={{
//         width: 32,
//         height: 32,
//         minWidth: 32,
//         minHeight: 32,
//         backgroundColor: "#3B82C9",
//         borderRadius: 3,
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         font: "Roboto",
//         color: "black",
//         fontSize: "14px",
//         fontWeight: "bold",
//       }}
//     >
//       <img alt="" src="logo_bot.png"/>
//     </div>
//   );
// };

const BotIcon = () => {
  return (
    <div
      style={{
        width: 32,
        height: 32,
        minWidth: 32,
        minHeight: 32,
        backgroundColor: "transparent",
        borderRadius: 3,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        font: "Roboto",
        color: "white",
        fontSize: "14px",
        fontWeight: "bold",
      }}
    >
      <img
        alt="Bot"
        src={userIcon}
        style={{
          maxWidth: '100%',
          maxHeight: '100%',
          width: 'auto', 
          height: 'auto' 
        }}
      />
    </div>
  );
};

const CustomUserMessage = (props) => {
  return (
    <div
      style={{
        minHeight: 83,
        paddingLeft: 29,
        paddingRight: 29,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <div
        style={{
          alignSelf: "flex-start",
          marginTop: 26,
        }}
      >
        <BotIcon />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginLeft: 21,
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        <span
          style={{
            color: "#000000",
          }}
        >
          {props.message}
        </span>
        {/* <DropdownButton id="dropdown-basic-button" title="Dropdown button">
        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
        </DropdownButton>  */}
      </div>
    </div>
    

  );
};

export default CustomUserMessage;
