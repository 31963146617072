import React, { useState, useEffect } from "react";
// import axios from "./ServerConnection";
import axios from 'axios';

import 'font-awesome/css/font-awesome.min.css';

//import React from 'react';
import Chatbot from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import "./MainApp.css";
import "./react-chatbot-styles.css";

import config from "./config";
import ActionProvider from "./ActionProvider";
import MessageParser from "./MessageParser";
import avatarImage from './chatbot_avatar.png'
import { useNavigate } from "react-router-dom";
import { BaseURL } from "./ServerConnection";

function App() {
  const [currentTime, setCurrentTime] = useState(0);
  const [getMessage, setGetMessage] = useState({});

  const [file, setFile] = useState([]);
  const [portfolioFile, setPorfolioFile] = useState([]);
  // const [portfolio, setPortfolio] = useState()
  const [frameworkFile, setFrameworkFile] = useState([])
  const [pdfUrl, setPdfUrl] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    navigate('/login');
  };


  function handleFileChange(event) {
    setFile([...file, ...Array.from(event.target.files)]);
  }
  function handlePortfolioChange(event) {
    setPorfolioFile([...portfolioFile, ...Array.from(event.target.files)]);
  }
  function handleFrameworkChange(event) {
    setFrameworkFile([...frameworkFile, ...Array.from(event.target.files)]);
  }
  // function handleRemove() {
  //   setFile([])
  // }
  function handleRemove(index, type) {
    if (type === "knowledge") {
      const newFiles = [...file];
      newFiles.splice(index, 1);
      setFile(newFiles);
    } else if (type === "portfolio") {
      const newFiles = [...portfolioFile];
      newFiles.splice(index, 1);
      setPorfolioFile(newFiles);
    } else if (type === "framework") {
      const newFiles = [...frameworkFile];
      newFiles.splice(index, 1);
      setFrameworkFile(newFiles);
    }
  }

  function handleFileRemove() {
    setFile(null);
  }
  function handlePortfolioRemove() {
    setPorfolioFile([])
  }
  function handleFrameworkRemove() {
    setFrameworkFile([]);
  }
  function toggleSidebar() {
    setSidebarOpen(!sidebarOpen);
  }

  // function handleSubmit(event) {
  //   event.preventDefault()
  //   const url = 'http://localhost:3000';
  //   const formData = new FormData();
  //   formData.append('file', file);
  //   formData.append('fileName', file.name);
  //   const config = {
  //     headers: {
  //       'content-type': 'multipart/form-data',
  //     },
  //   };
  //   axios.post(url, formData, config).then((response) => {
  //     console.log(response.data);
  //   });

  // }

  // This is just for testing. 
  useEffect(() => {
    axios.get(`${BaseURL}/time`).then((response) => {
      setCurrentTime(new Date(response.data.time * 1000));
    });


    axios
      .get(`${BaseURL}/flask/runbot`)
      .then((response) => {
        setGetMessage(response);
      })
      .catch((error) => {
        console.log(error);
      });

    // axios
    //   .post("/flask/runbot", {
    //     message: "How are you?",
    //     type: "custom",
    //   })
    //   .then(
    //     (response) => {
    //       console.log(response);
    //     },
    //     (error) => {
    //       console.log(error);
    //     }
    //   );
  }, []);

  return (

    <div className="App">
      <div className="top-left-image-container">
        {/* <img src={avatarImage} alt="Bot" style={{ maxWidth: '100%', maxHeight: '100%' }} /> */}
      </div>
      
      <div className="parent-upload">


        {/* Toggle Sidebar Button */}

        <div className="icon-container" onClick={toggleSidebar}>
          <i className="fa fa-bars" aria-hidden="true"></i>
        </div>

        {/* Sidebar JSX */}
        <div className={`sidebar ${sidebarOpen ? "sidebar-open" : ""}`}>
          <span className="sidebar-close-btn white-text" onClick={toggleSidebar}>&times;</span>

          {/* Place buttons inside the sidebar */}
          <div className="file-upload-container white-text">
            <h1 className="file-upload-header white-text">Knowledge Upload</h1>
            <form className="upload">
              <input type="file" onChange={handleFileChange} multiple accept=".pdf" id="knowledge-upload" style={{ display: 'none' }} />
              <label htmlFor="knowledge-upload" className="custom-file-upload-button">Choose PDFs</label>
              {file && file.length > 0 && <div className="uploaded-file">
                {file.map((f, index) => (
                  <div key={f.name} className="uploaded-file-div">
                    <div className="uploaded-file-name">{f.name}</div>
                    <span onClick={() => handleRemove(index, "knowledge")} className="remove-icon">X</span>
                  </div>
                ))}
              </div>}
              {/* <button onClick={handleRemove} className="custom-button">Remove PDF</button> */}
            </form>
          </div>
          <div className="file-upload-container white-text">
            <h1 className="file-upload-header white-text">Portfolio Upload</h1>
            <form className="upload">
              <input type="file" onChange={handlePortfolioChange} multiple accept=".pdf" id="portfolio-upload" style={{ display: 'none' }} />
              <label htmlFor="portfolio-upload" className="custom-file-upload-button">Choose PDFs</label>
              {portfolioFile && portfolioFile.length > 0 && <div className="uploaded-file">
                {portfolioFile.map((f, index) =>
                (
                  <div key={f.name} className="uploaded-file-div">
                    <div className="uploaded-file-name">{f.name}</div>
                    <span onClick={() => handleRemove(index, "portfolio")} className="remove-icon">X</span>
                  </div>
                ))}
              </div>}
              {/* <button onClick={handlePortfolioRemove} className="custom-button" >Remove PDF</button> */}
            </form>
          </div>
          <div className="file-upload-container white-text">
            <h1 className="file-upload-header white-text">Framework Upload</h1>
            <form className="upload">
              <input type="file" onChange={handleFrameworkChange} multiple accept=".pdf" id="framework-upload" style={{ display: 'none' }} />
              <label htmlFor="framework-upload" className="custom-file-upload-button">Choose PDFs</label>
              {frameworkFile && frameworkFile.length > 0 && <div className="uploaded-file">
                {frameworkFile.map((f, index) => (
                  <div key={f.name} className="uploaded-file-div">
                    <div className="uploaded-file-name">{f.name}</div>
                    <span onClick={() => handleRemove(index, "framework")} className="remove-icon">X</span>
                  </div>
                ))}
              </div>}
              {/* {framework && <div className="uploaded-file">{framework.name}</div>} */}
              {/* <button onClick={handleFrameworkRemove} className="custom-button" >Remove PDF</button> */}
            </form>
          </div>
        </div>

        {/* <div className="file-upload-container">
          <h1 className="file-upload-header">Knowledge Upload</h1>
        <form className="upload">
        <input 
            type="file" 
            onChange={handleFileChange} 
            multiple accept=".pdf" 
            id="knowledge-upload" 
            style={{display: 'none'}}
        />
        <label htmlFor="knowledge-upload" className="custom-file-upload-button">Choose PDFs</label>
                {file && file.length > 0 && 
            <div className="uploaded-file">
            {file.map(f => <div key={f.name}>{f.name}</div>)}
              </div>
                }
        <button onClick={handleRemove}>Remove PDF</button>
          </form>
        </div> */}





      </div>
      
      <header className="App-header">
        
      <button onClick={handleLogout} style={{ position: 'absolute', top: 12, right: 50, padding: '5px' }}>Logout</button>

        <Chatbot
          config={config}
          actionProvider={ActionProvider}
          messageParser={MessageParser}
          headerText=" "
        />
        <br />
        <br />
        <div className="response-area">
          <h2>Backend Messages for Testing</h2>
          <p>The current time is {currentTime.toString()}.</p>
          {getMessage.status === 200 ? (
            <h3>Message from Backend: {getMessage.data.message}</h3>
          ) : (
            <h3>LOADING</h3>
          )}
        </div>
      </header>

      
    </div>
  );
}

export default App;
