import React from "react";
import CustomFirstMessageButton from "./components/CustomFirstMessage/CustomFirstMessageButton";

const CustomFirstMessage = (props) => {
  if (props.state.messages.length > 4) return;
  return (
    <div
      style={{
        minHeight: 83,
        paddingTop: 10,
        paddingLeft: 15,
        paddingRight: 15,
      }}
    >
      <h3
        style={{
          color: "#000000",
          margin: "0 0 20px 0",
        }}
      >
        Information Gathering AI
      </h3>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <CustomFirstMessageButton
          text="Sample Question 1"
          {...props}
        />
        <CustomFirstMessageButton
          text="Sample Question 2"
          {...props}
        />
        <CustomFirstMessageButton
          text="Sample question 3"
          {...props}
        />
      </div>
    </div>
  );
};

export default CustomFirstMessage;
