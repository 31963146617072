import React from "react";
import { createClientMessage } from 'react-chatbot-kit';
import "./CustomFirstMessageButton.css";

const CustomFirstMessageButton = ({
  text,
  setState,
  actions,
}) => {
  return (
    <button
      className="custom-first-message-button"
      onClick={() => {
        const message = createClientMessage(text);
        setState((prev) => ({
          messages: [...prev.messages, message],
        }));
        actions.handleMessage(message);
      }}
    >
      { text }
    </button>
  );
};

export default CustomFirstMessageButton;
