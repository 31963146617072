import React, { useEffect, useState } from "react";
// import axios from "./ServerConnection";
import axios from 'axios';
import { createCustomMessage } from "react-chatbot-kit";
import { BaseURL } from "./ServerConnection";


// This function defines all of the actions that the chatbot can perform, and is passed to the MessageParser component.
const ActionProvider = ({
  createChatBotMessage,
  setState,
  children,
  ...rest
}) => {
  const [data, setData] = useState("Which category would you like to save information under: 'profile', 'document', 'finances', 'contacts', or 'other'?");

  /* These functions are not being called by MessageParser.jsx. 
  // This could be commented out -- currently not using.    
  const handleHello = () => {
    const botMessage = createChatBotMessage("Hello. Nice to meet you.");

    setState((prev) => ({
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleDefault = () => {
    //const botMessage = createChatBotMessage("I don't know how to respond");
    const botMessage = createCustomMessage(
      "this is a custom message",
      "custom"
    );
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleJavascriptList = () => {
    const botMessage = createChatBotMessage(
      "Fantastic, I've got the following resources for you on Javascript:",
      {
        widget: "javascriptLinks",
      }
    );

    setState((prev) => ({
      messages: [...prev.messages, botMessage],
    }));
  };
  */ 
  // This function is called by MessageParser.jsx.  It's the main workhorse of the chatbot that calls the backend endpoints that's defined in the Flask app (BotApiHandler.py)
  const handleMessage = (message) => {
    // axios
    // .post("/flask/tool", {
    //   message: message
    // })
    // .then(
    //   (response) => {
    //     let message = createCustomMessage(
    //       response.data,
    //       "custom",
    //       {
    //         payload: {
    //           message: response.data,
    //           first: "yes"
    //         },
    //       }
    //     );
    //     setState((prev) => ({
    //       messages: [...prev.messages, message],
    //     }));
    //   },
    //   (error) => {
    //     console.log(error);
    //   }
    // );


    // latest API as of 02/08/24
    axios
    .post(
      `${BaseURL}/flask/cbot`, {
        user_response: message,
        prev_question: data,
    })
    .then(
      (response) => {
        let message = createCustomMessage(
          response.data,
          "custom",
          {
            payload: {
              message: response.data,
              first: "no"
            },
          }
        );
        setState((prev) => ({
          // messages: [...prev.messages, message],
          messages: [...prev.messages].concat(message),
        }));
        setData(message.message)
      },
      (error) => {
        console.log(error);
      }
    );


    // CBOT API used till 02/08/24
    // axios
    // .post(
    //   `${BaseURL}/flask/cbot`, {
    //   message: message,
    // })
    // .then(
    //   (response) => {
    //     let message = createCustomMessage(
    //       response.data,
    //       "custom",
    //       {
    //         payload: {
    //           message: response.data,
    //           first: "no"
    //         },
    //       }
    //     );
    //     setState((prev) => ({
    //       // messages: [...prev.messages, message],
    //       messages: [...prev.messages].concat(message),
    //     }));
    //     setData(message.message)
    //   },
    //   (error) => {
    //     console.log(error);
    //   }
    // );

    // axios
    //   .post("/flask/runbot", {
    //     message: message,
    //     type: "custom",
    //   })
    //   .then(
    //     (response) => {
    //       let message = createCustomMessage(
    //         response.data.message,
    //         response.data.type,
    //         {
    //           payload: {
    //             message: response.data.message,
    //           },
    //         }
    //       );
    //       setState((prev) => ({
    //         messages: [...prev.messages, message],
    //       }));
    //     },
    //     (error) => {
    //       console.log(error);
    //     }
    //   );
  };


  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            // handleHello,
            //handleDefault,
            //handleJavascriptList,
            handleMessage,
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
