// import axios from "axios";

// const debug = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

// if (debug) {
//   axios.defaults.baseURL = "https://rejara-yenz34nmua-uc.a.run.app";
// }

// export default axios;
// export const BaseURL= "https://rejara-yenz34nmua-uc.a.run.app"
export const BaseURL= "https://rj-flash-yenz34nmua-uc.a.run.app/"
// export const BaseURL= "https://de1f-112-196-54-35.ngrok-free.app"  URL for 02/08/24