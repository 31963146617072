import React from "react";
import avatarImage from './chatbot_avatar.png'
import torchImage from './Torch.PNG'
import CircularProgress from '@mui/material/CircularProgress';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import "./MainApp.css";
import { useEffect, useState } from "react";
import InputBase from '@mui/material/InputBase';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: "transparent",
    fontSize: 16,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
}));

const UserIcon = () => {
  return (
    <div
      style={{
        width: 32,
        height: 32,
        minWidth: 32,
        minHeight: 32,
        backgroundColor: "#3B82C9",
        borderRadius: 3,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        font: "Roboto",
        color: "black",
        fontSize: "14px",
        fontWeight: "bold",
      }}
    >
      <img alt="" src="logo_bot.png" />
    </div>
  );
};

const BotIcon = () => {
  return (
    <div
      style={{
        width: 32,
        height: 32,
        minWidth: 32,
        minHeight: 32,
        backgroundColor: "transparent",
        borderRadius: 3,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        font: "Roboto",
        color: "white",
        fontSize: "14px",
        fontWeight: "bold",
      }}
    >
      <img
        alt="Bot"
        src={torchImage}
        style={{
          maxWidth: '100%',
          maxHeight: '100%',
          width: 'auto',
          height: 'auto'
        }}
      />
    </div>
  );
};

const CustomBotMessage = (props) => {
  const isBlink = props.payload.first;
  const { message, isBotMessage } = props.payload;

  // Log the message type and value for debugging
  console.log("Message received:", message.question);
  console.log("Type of message:", typeof message.question);

  const message_result = typeof message.question === 'string' ? message.question.split('@')[0] : '';

  const [displayResponse, setDisplayResponse] = useState("");
  const [completedTyping, setCompletedTyping] = useState(false);

  useEffect(() => {
    setCompletedTyping(false);

    let i = 0;
    const stringResponse = message_result;

    const intervalId = setInterval(() => {
      setDisplayResponse(stringResponse.slice(0, i));

      i++;

      if (i > stringResponse.length) {
        clearInterval(intervalId);
        setCompletedTyping(true);
      }
    }, 20);

    return () => clearInterval(intervalId);
  }, [message_result]);

  const tool_usage = typeof message === 'string' ? message.split('@')[1] : '';

  return (
    <div
      style={{
        minHeight: 83,
        paddingLeft: 29,
        paddingRight: 29,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "#D8EBFF80",
        borderWidth: "1px 0px",
        borderStyle: "solid",
        borderColor: "rgba(164, 198, 229, 0.5)",
      }}
    >
      <div
        style={{
          alignSelf: "flex-start",
          marginTop: 26,
        }}
      >
        <BotIcon />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginLeft: 21,
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        {isBlink === "no" && <span style={{ color: "#333333" }}>{displayResponse}</span>}
      </div>
    </div>
  );
};

export default CustomBotMessage;
